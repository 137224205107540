import React from 'react'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import ContactForm from '../components/ContactForm'

const Contact = ({ data }) => {
  return (
    <Layout>
      <GatsbySeo
      title='Contact Us | listadecasinos.net'
      description='Contact us'
      
    />
      <Container>
        <PageTitle>Contact Us</PageTitle>
        <div><center>&#128231; info@listadecasinos.net</center></div>
        <ContactForm />
      </Container>
    </Layout>
  )
}

export default Contact
